<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CCard>
			<CCardBody>
				<TheTableHeader
					:header="'FORMULIR PENGATURAN'"
					:subHeader="'PENGUNJUNG DATA CENTER DI LINTASARTA.'"
				/>
			</CCardBody>
		</CCard>

		<CRow>
			<CCol sm="6">
				
				<CCard>
					<CCardHeader>
						<strong>Form {{id > 0 ? 'Perubahan' : 'Penambahan'}}</strong> Data Pengunjung Data Center.
					</CCardHeader>
					<CCardBody>
						<CForm>
							<CInput
								type="text"
								description=""
								label="ID Pengguna"
								v-model="id"
								plaintext
								readonly
							/>
							<CInput
								v-if="admin <= 2"
								type="text"
								label="Nama Perusahaan Saat Registrasi"
								placeholder=""
								v-model="note"
								readonly
							/>
							<CSelect 
								v-if="admin < 3"
								:value.sync="company_id"
								name="company_id"
								label="Nama Perusahaan*"
								placeholder="Pilih Perusahaan/Instansi"
								:options="companies"
							/>
							<CSelect 
								v-if="admin <= 2 && role === 3 || role === 5"
								:value.sync="site"
								name="site"
								label="Bertugas di Data Center"
								placeholder="Pilih Data Center"
								:options="sites"
							/>
							<CInput
								:readonly="page.split('/')[6] === 'approve'"
								type="text"
								label="Nama Lengkap"
								placeholder="Masukkan nama Anda.."
								v-model="name"
								required
							/>
							<!-- <CInput
								type="text"
								label="No Kartu Akses"
								v-model="card_id"
								disabled
							/> -->
							<CInput
								:readonly="id > 0 || page.split('/')[6] === 'approve'"
								type="email"
								label="Alamat Email"
								placeholder="Masukkan email perusahaan.."
								v-model="email"
								required
							/>
							<CInput
								:readonly="page.split('/')[6] === 'approve'"
								type="number"
								label="Nomor ID Card"
								v-model="id_card_number"
								required
							/>
							<CInput
								:readonly="page.split('/')[6] === 'approve'"
								type="number"
								label="No Handphone"
								v-model="mobile_phone"
								required
							/>
							<!-- <CInput
								type="password"
								label="Password"
								placeholder="Masukkan password.."
								v-model="password"
								required
							/> -->
							<input
								id="ktpUpload"
								hidden
								ref="ktp"
								type="file"
								accept="image/png,image/jpg,image/jpeg"
								@change="selectKtp"
							/>
							<h6 v-if="id > 0">Foto Kartu Identitas</h6>
							<img
                v-if="id > 0 && !ktp && page.split('/')[6] !== 'approve'"
                style="border: 1px solid #f4f4f4; border-radius: 6px; height: auto; width: 480px;"
                src="@/assets/icons/ktp.png"
								@click="openKtp"
              >
              <img
                v-if="id > 0 && ktp && page.split('/')[6] !== 'approve'"
                style="border: 1px solid #f4f4f4; border-radius: 6px; height: auto; width: 480px;"
                :src="'https://dcvisit.lintasarta.net/api/public/ktp/' + ktp"
								@click="openKtp"
              >
							<p><small>klik untuk mengunggah foto KTP.</small></p>

							<CSelect 
								v-if="admin <= 2 "
								:value.sync="role"
								name="role"
								label="Jenis Pengguna*"
								placeholder="Pilih Role Pengguna"
								:options="roles"
							/>
							<CSelect 
								v-if="admin <= 2 && +user.user.id !== +id"
								:value.sync="status"
								name="status"
								label="Status Pengguna*"
								placeholder="Pilih Status Pengguna"
								:options="statuses"
							/>
						</CForm>
					</CCardBody>
					<CCardFooter v-if="admin <= 2 || admin === 4">
						<CButton type="submit" size="sm" color="primary" @click="id === '0' ? createUser() : updateUser()"><CIcon name="cil-check-circle"/> Save</CButton> &nbsp;&nbsp;
						<CButton type="reset" size="sm" color="danger" @click="resetForm()"><CIcon name="cil-ban"/> Reset</CButton> &nbsp;&nbsp;
						<CButton type="cancel" size="sm" color="dark" @click="goBack()"><CIcon name="cil-ban"/> Cancel</CButton>
					</CCardFooter>
				</CCard>
				<CCard v-if="admin <= 2 && status === 'Active'">
					<CCardHeader>
						<strong>Pengaturan</strong> Hak Akses Ruangan di Data Center.
					</CCardHeader>
					<CCardBody>
						<CSelect 
							:value.sync="site_id"
							name="site_id"
							label="Nama Data Center"
							placeholder="Pilih Lokasi Data Center"
							:options="sites"
							@change="siteChange($event)"
						/>

						<input inline id="all" type="checkbox" @click="selectAll" v-model="allSelected">&nbsp;
						<label for="all"> <h6>{{allSelected ? 'Semua' : ''}} Ruangan Boleh Dikunjungi</h6></label>

						<p v-if="rooms.length === 0">PILIH LOKASI DATA CENTER DAHULU.</p>

						<CRow>
							<CCol sm="6" v-for="item in rooms" :key="item.id">
								<input inline type="checkbox" :id="item.value" :value="item.value" v-model="allowing_access">&nbsp;
								<label :for="item.value"> {{item.label}}</label>
							</CCol>
						</CRow>
						<CRow style="margin-top: 16px;">
							<CCol sm="8"></CCol>
							<CCol sm="4">
								<CButton type="submit" size="sm" color="danger" block @click="saveAccess()">
									<CIcon name="cil-check-circle"/> Beri Akses
								</CButton>
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
			<CCol sm="6">
				<CCard v-if="page.split('/')[6] !== 'approve'">
					<CCardHeader>
						<strong>NON-DISCLOSURE</strong> AGREEMENT (NDA) - {{expired_at !== null ? 'VALID HINGGA ' + expired_at : 'BELUM MEMBACA NDA'}}
					</CCardHeader>
					<CCardBody>
            <p>Dengan ini menyatakan bahwa kami sebagai staf/karyawan dari Perusahaaan/Instansi tersebut di atas, memahami atau mematuhi hal berikut ini :
            <br><br><strong>• Wajib menjaga kerahasiaan data dan informasi milik PT. Aplikanusa Lintasarta serta tidak menyalahgunakan baik untuk kepentingan sendiri, kepentingan perusahaan/instansi ataupun pihak lain.
            <br>• Client atau Pengunjung Datacenter tetap terikat pada kebijakan keamanan informasi yang telah dianut oleh perusahaan/instansi asal pelanggan tersebut.
            <br>• Client atau pengunjung Datacenter wajib memakai tanda pengenal yang telah diberikan PT. Aplikanusa Lintasarta pada waktu kunjungan ke Datacenter.
            <br>• Keamanan perangkat lunak pada perangkat Colocation merupakan tanggung jawab client atau pelanggan Datacenter.
            <br>• PT. Aplikanusa Lintasarta bertanggung jawab atas keamanan fisik baik dari segi lokasi, rak dan kandang (cage), baik ketika pelanggan atau pengunjung Datacenter sedang tidak ada di Datacenter ataupun berada di Datacenter..
            <br>• Ketika client atau pengunjung Datacenter sedang melakukan konfigurasi, keamanan aset pelanggan adalah tanggung jawab client atau pengunjung Datacenter yang bersangkutan.
            <br>• Client atau pengunjung Datacenter tidak diperbolehkan berinteraksi dengan aset yang dimiliki oleh pelanggan lain kecuali telah memiliki dasar dalam perjanjian kerja sama yang sah.
            <br>• Client yang menggunakan rak bersama (sharing) dengan pelanggan lain , dilarang menggunakan aset yang bukan milik pelanggan yang bersangkutan. Hal ini perlu mendapat perlakuan khusus untuk mencegah kabel terputus atau penempatan aset yang tidak seharusnya dari pelanggan tersebut.
            <br>• Jika client menyimpan dan melakukan transmisi cardholder data, maka tanggung jawab untuk mematuhi persyaratan PCI DSS selama bekerja dan melakukan layanan smarthand services terletak pada sisi pelanggan (client).
            <br>• Sesuai dengan PCI DSS dan ISO 27001, PT. Aplikanusa Lintasarta akan memberlakukan proses pengamanan yang ketat terhadap perlindungan informasi dan menjaga kontinuitas bisnis.
            <br>• Telah membaca & memahami Kebijakan Keamanan Informasi milik PT. Aplikanusa Lintasarta.</strong>
            <br>
            <br>Apabila terjadi pelanggaran maka kami bersedia untuk bertanggung jawab sesuai hukum perundang-undangan yang berlaku.</p>
            <br><br>
            <CRow class="mt-3 text-center">
              <CCol sm="12" class="text-center" v-if="signature">
                <div class="container" style="border: solid 2px transparent; border-radius: 5px;">
                  <p class="sign">Tanda Tangan</p>
									<img
                  	style="height: 200px; width: 100%;"
                  	:src="'https://dcvisit.lintasarta.net/' + signature.replace(/\\\//g, '/').substr(15, signature.length)"
                	>
                	<p>{{user.name}}</p>
								</div>
              </CCol>
							<CCol sm="12" class="text-center" v-if="!signature && id > 0">
								<div class="container" v-bind:class="{ disabled: admin !== 5 }">
                  <p class="sign">Tanda Tangan</p>  
                  <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="200px"
                    ref="signaturePad"
                    :options="options"
                  />
                </div>
                <div class="buttons">
                  <CButton size="sm" color="warning" @click="undo" :disabled="admin !== 5">Undo</CButton>
                  <CButton size="sm" color="danger" @click="clear" :disabled="admin !== 5">Clear</CButton>
                  <CButton size="sm" color="success" @click="save(signature)" :disabled="admin !== 5">Save</CButton>
                </div>
							</CCol>
            </CRow>
          </CCardBody>
        </CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import http from '@/utils/http-common';
import { mapState } from 'vuex'
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'VisitorManagement',
	components: { TheTableHeader },
	data () {
		return {
			page: window.location.href,
			admin: +localStorage.getItem('role'),
			companies: [],
			sites: [],
			rooms: [],
			roles: [],
			id: this.$route.params.id,
			site_id: 1,
			site: 0,
			name: '',
			note: '',
			email: '',
			ktp: '',
			mobile_phone: '',
			id_card_number: '',
			password: '',
			password_confirmation: '',
			company_id: 0,
			card_id: 0,
			status: 'InActive',
			expired_at: null,
			signature: null,
			allSelected: false,
			allowing_access: [],
			role: 9,
			statuses: [
				'Active', 'InActive'
			],
			options: {
        dotSize: '0.1',
        penColor: "#333",
      },
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Pengunjung',
          href: '#/settings/users'
        }, 
        {
          text: 'Manajemen Pengunjung'
        }
      ],
		}
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 4000,
        dismissible: true
      })
    },
		goBack() {
      this.$router.go(-1);
    },
		openKtp() {
      document.getElementById('ktpUpload').click()
    },
		selectKtp() {
			let formData = new FormData();
			const maxAllowedSize = 2 * 1024 * 1024;
      if (this.$refs.ktp.files[0].size > maxAllowedSize) {
      	this.toast('Maksimal ukuran gambar 2MB', 'error')
      } else {
				formData.append('ktp', this.$refs.ktp.files[0]);

    		return http.post('/users/ktp/' + this.id, formData, {
      		headers: {
        		'Content-Type': 'multipart/form-data'
      		}
    		}).then(() => {
					this.toast('Upload KTP berhasil.', 'info')
					this.getUser()
				});
			}
    },
		getCompanies() {
			const self = this;
			return http.get('/companies')
			.then(function (response) {
				const data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['company_name'];
					items['value'] = data[i]['id'];
					self.companies.push(items)
				}
				console.log(self.companies)
			}).catch(function (error) {
				console.log(error);
			});
		},
		getRoles() {
			const self = this;
			return http.get('/roles')
			.then(function (response) {
				const data = response.data.roles;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['name'];
					items['value'] = data[i]['id'];
					self.roles.push(items)
				}

				if (self.admin > 1) {
					self.roles = self.roles.filter(function(item) {
    			  return (item.value > 2);  
				  });
				}
				// console.log(self.roles)
			}).catch(function (error) {
				console.log(error);
			});
		},

		validator (val) {
			return val ? val.length >= 4 : false
		},

		undo() {
      this.$refs.signaturePad.undoSignature();
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    save(state) {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      let self = this;
      if (state) {
        return http.put('/ndas/' + self.id, {
				  user_id: self.id,
          signature: data,
			  })
			  .then(function (response) {
          // console.log(response.message);
					self.toast('Berhasil memperbarui tandatangan.', 'info');
					self.getUser();
				  /* console.log(isEmpty);
          console.log(data); */
			  }).catch(function (error) {
				  console.log(error);
			  });
      } else {
        return http.post('/ndas', {
				  user_id: self.id,
          signature: data,
			  })
			  .then(function (response) {
          // console.log(response.message);
					self.toast('Berhasil menambahkan tandatangan.', 'info');
					self.getUser();
				  /* console.log(isEmpty);
          console.log(data); */
			  }).catch(function (error) {
					self.toast(error, 'error')
				  console.log(error);
			  });
      }
    },

		createUser() {
			let self = this;
			return http.post('/users', {
				name: self.name,
				email: self.email,
				password: self.password,
				password_confirmation: self.password,
				company_id: self.company_id,
				role: self.role,
				site_id: self.site,
				mobile_phone: self.mobile_phone,
				id_card_number: self.id_card_number,
				status: 'InActive'
			})
			.then(function (response) {
				self.toast('Pengunjung Berhasil Ditambahkan.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		updateUser() {
			let self = this;
			return http.put('/users/' + self.id, {
				name: self.name,
				email: self.email,
				company_id: self.company_id,
				role: self.role,
				site_id: self.site,
				mobile_phone: self.mobile_phone,
				id_card_number: self.id_card_number,
				status: self.status
			})
			.then(function (response) {
				self.toast('Pengunjung Berhasil Diubah.', 'info')
				self.$router.go(-1);
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
		resetForm() {
			let self = this;
			self.name = '';
			self.note = '';
			self.company_id = 0;
			self.site_id = 1;
			self.site = 0;
			self.id_card_number = '';
			self.mobile_phone = '';
			self.email = '';
			self.password = '';
			self.role = 0;
			self.status = 'InActive'
		},
		getUser() {
			let self = this;
			if (self.id > 0) {
				return http.get('/users/' + self.id)
				.then(function (response) {
					// console.log(response.data.user)
					let user = response.data.user;
					
					self.company_id = user.company_id;
					self.note = user.note;
					self.name = user.name;
					self.email = user.email;
					self.role = user.roles[0]['id'];
					self.site = user.site_id;
					self.status = user.status;
					self.ktp = user.ktp;
					self.id_card_number = user.id_card_number;
					self.mobile_phone = user.mobile_phone;
					self.signature = user.signature;
					self.expired_at = user.expired_at;
					// self.card_id = user.card[0]['id'] || 0;
					/* response.data.user.forEach((value, index) => {
    				self.company_id = value.company_id;
						self.name = value.name;
						self.email = value.email;
						self.role = value.roles[0]['id'];
						self.status = value.status;
						self.ktp = value.ktp;
						self.signature = value.signature;
						self.expired_at = value.expired_at;
					}); */
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		getSites() {
			const self = this;
			return http.get('/sites')
			.then(function (response) {
				const data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['site_name'];
					items['value'] = data[i]['id'];
					self.sites.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		siteChange(event) {
			// console.log(event.target.value)
			this.rooms = [];
			this.getRoomsBySite(event.target.value)
		},
		getRoomsBySite(id) {
			const self = this;
			return http.get('/rooms/site/' + id)
			.then(function (response) {
				const data = response.data.room;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['room_name'];
					items['value'] = data[i]['id'];
					self.rooms.push(items)
				}
				self.getAccess(self.id, id);
			}).catch(function (error) {
				console.log(error);
			});
		},
		getAccess(user_id, site_id) {
			// console.log('user', user_id)
			// console.log('site', site_id)
			let self = this;
			self.allowing_access = [];
			
			return http.get('/roomrestrict/user/' + user_id + '/' + site_id)
				.then(function (response) {
					// console.log(response.data.data)
					if (response.data.data) {
						self.allowing_access = response.data.data.split(',');
					}
				}).catch(function (error) {
					console.log(error);
				});
		},
		/* createAccess() {
			let self = this;
			return http.post('/roomrestrict', {
				site_id: self.site_id,
				user_id: self.id,
				room_id: self.allowing_access.toString()
			})
			.then(function (response) {
				// self.$router.go(-1);
			}).catch(function (error) {
				console.log(error);
			});
		}, */
		selectAll() {
			let self = this;
			if (self.allSelected) {
				self.allowing_access = [];
			} else {
				self.rooms.forEach((room, index) => {
        	self.allowing_access.push(room.value.toString());
      	})
			}
		},
		saveAccess() {
			let self = this;
			return http.put('/roomrestrict/' + self.id, {
				// user_id: self.id,
				site_id: self.site_id,
				room_id: self.allowing_access.toString()
			})
			.then(function (response) {
				// console.log(response)
				// self.$router.go(-1);
				// self.updateUser();
				self.toast('Akses Ruangan Berhasil Diubah.', 'info')
			}).catch(function (error) {
				self.toast(error, 'error')
				console.log(error);
			});
		},
	},
	computed: {
    ...mapState(['user']),
  },
	mounted() {
		let self = this;
		// console.log(self.page.split('/')[6])

		if (self.id > 0) {
			self.getUser().then(() => {
				return self.getSites();
			}).then(() => {
				return self.getRoomsBySite(self.site_id);
			}).then(() => {
				self.getAccess(self.id, self.site_id);
			});
		}
		
		self.getCompanies();
		self.getRoles();
	}
}
</script>
<style scoped>
#signature {
  border: solid 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: 100%;
  padding: 8px 16px;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.sign {
  text-align: center;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>
